.contact_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 60px;
  height: 60px;
  background-color: var(--primary);
  outline: none;
  border: none;
  color: var(--primary-1);
  border-radius: 50%;
  cursor: pointer;
}
