/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l11 2s infinite;
}
@keyframes l11 {
  0% {
    background: conic-gradient(var(--primary) 0, #0000 0);
  }
  12.5% {
    background: conic-gradient(var(--primary) 45deg, #0000 46deg);
  }
  25% {
    background: conic-gradient(var(--primary) 90deg, #0000 91deg);
  }
  37.5% {
    background: conic-gradient(var(--primary) 135deg, #0000 136deg);
  }
  50% {
    background: conic-gradient(var(--primary) 180deg, #0000 181deg);
  }
  62.5% {
    background: conic-gradient(var(--primary) 225deg, #0000 226deg);
  }
  75% {
    background: conic-gradient(var(--primary) 270deg, #0000 271deg);
  }
  87.5% {
    background: conic-gradient(var(--primary) 315deg, #0000 316deg);
  }
  100% {
    background: conic-gradient(var(--primary) 360deg, #0000 360deg);
  }
}
