@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  --primary: #7853a2;
  --primary-1: #f9f7fc;
  --primary-a1: #f9f7fc05;
  --primary-2: #f2eef9;
  --primary-a2: #f2eef90d;
  --primary-3: #e4dcf2;
  --primary-a3: #e4dcf212;
  --primary-4: #d1c1e6;
  --primary-a4: #d1c1e62f;
  --primary-5: #b69bd7;
  --primary-a5: #b69bd742;
  --primary-6: #9773c2;
  --primary-7: #7853a2;
  --primary-8: #654388;
  --primary-9: #54396f;
  --primary-10: #48325d;
  --primary-11: #2a183a;

  --gray: #8b8d98;
  --gray-1: #f6f6f7;
  --gray-a1: #f6f6f705;
  --gray-2: #eef0f1;
  --gray-a2: #eef0f10d;
  --gray-3: #e0e2e5;
  --gray-a3: #eef0f112;
  --gray-4: #cdcfd4;
  --gray-a4: #cdcfd42f;
  --gray-5: #b8bbc1;
  --gray-a5: #b8bbc142;
  --gray-6: #a4a8b0;
  --gray-a6: #a4a8b059;
  --gray-7: #8b8d98;
  --gray-a7: #8b8d9878;
  --gray-8: #7b7d87;
  --gray-a8: #7b7d87c2;
  --gray-9: #65666e;
  --gray-a9: #7b7d87c9;
  --gray-10: #54555b;
  --gray-a10: #7b7d87c6;
  --gray-11: #313235;
  --gray-a11: #313235e2;
  font-size: 16px;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
}

/* container  */
.container {
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;

  @media (min-width: 768px) {
    width: 750px;
    padding-right: 0px;
    padding-left: 0px;
  }
  @media (min-width: 992px) {
    width: 970px;
    padding-right: 0px;
    padding-left: 0px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.section_title {
  color: var(--primary-9);
  text-transform: uppercase;
  font-size: 2.4rem;
}

button {
  font-family: inherit;
}

/* reset styles for vertical library   */
/* .vertical-timeline {
  --line-color: var(--primary-10);
} */
.vertical-timeline-element-icon svg {
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}
