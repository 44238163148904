.services {
  padding: 50px 0;
}
.services h2 {
  text-align: center;
  margin-bottom: 1rem;
}
.services .description {
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  color: var(--primary-8);
  font-size: 1.2rem;
}
.services .cards_wrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.services .cards_wrapper .service_card {
  padding: 1rem;
  border: 1px solid var(--primary-8);
  border-radius: 0.5rem;
  background-color: var(--primary-a1);
  width: 240px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services .cards_wrapper .service_card > div {
  padding: 0.5rem;
  background-color: var(--primary-1);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services .cards_wrapper .service_card h3 {
  font-size: 1.1rem;
  text-transform: capitalize;
  margin-bottom: 0.25rem;
}
.services .cards_wrapper .service_card p {
  color: var(--gray-9);
  text-align: center;
}

.get_service_btn {
  width: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  margin-top: 1rem;
  background: linear-gradient(var(--primary-7), var(--primary-6));
  border-radius: 0.25rem;
  color: var(--gray-1);
  text-transform: capitalize;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}
