.contact_us {
  min-width: 290px;
  @media only screen and (min-width: 768px) {
    min-width: 600px;
  }
}
.contact_us h2 {
  text-transform: capitalize;
}
.contact_us .contact_form {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.contact_us .contact_form > button {
  border: 1px solid var(--primary-9);
  padding: 0.5rem;
  border-radius: 0.25rem;
  outline: None;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  background-color: var(--primary-7);
  color: var(--gray-2);
  text-transform: uppercase;
  font-family: inherit;
}

.contact_form > a {
  background-color: #128c7e;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  color: var(--gray-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.actions_divider {
  text-align: center;
  position: relative;
}
.actions_divider::before {
  content: '';
  height: 2px;
  width: 40%;
  background-color: var(--gray-4);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.actions_divider::after {
  content: '';
  height: 2px;
  width: 40%;
  background-color: var(--gray-4);
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
