.footer {
  padding: 100px 0;
  background-color: var(--primary-10);
  color: var(--primary-1);
}

.footer .footer_container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.footer .footer_container > div {
  flex: 1;
  min-width: 290px;
}
.footer .footer_container > div:nth-of-type(1) p {
  color: var(--primary-3);
  text-transform: capitalize;
  line-height: 1.8rem;
  margin-top: 1rem;
}
.footer .footer_container > div:nth-of-type(2) h3,
.footer .footer_container > div:nth-of-type(3) h3 {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.footer .footer_container > div:nth-of-type(2) a {
  color: var(--primary-3);
  display: inline-block;
  padding: 5px 0;
  text-transform: capitalize;
  transition: 300ms;
}
.footer .footer_container > div:nth-of-type(2) a:hover {
  color: var(--primary-1);
  padding: 0.5rem 1rem;
}
.footer .footer_container > div:nth-of-type(3) ul {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.footer .footer_container > div:nth-of-type(3) ul li {
  border: 1px solid var(--primary-3);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.footer .footer_container > div:nth-of-type(3) ul li a:hover {
  color: var(--primary-10);
  fill: var(--primary-10);
}
.footer .footer_container > div:nth-of-type(3) ul li a {
  line-height: 0;
}

.footer .location {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  text-decoration: underline;
}
