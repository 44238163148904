.hero {
  height: 100vh;
  height: 100svh;
  min-height: 500px;
  max-height: 800px;
  background: linear-gradient(to bottom, var(--primary-2), var(--primary-4));
  position: relative;
  @media only screen and (max-width: 991px) {
    padding-top: 80px;
  }
}
.hero .hero_container {
  height: 100vh;
  height: 100svh;
  min-height: 500px;
  max-height: 800px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.hero .hero_container > h1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 4rem;
  max-width: fit-content;
  /* overflow: hidden; */
  text-transform: capitalize;
  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
}

.hero .hero_container > h1 span:nth-of-type(2) {
  color: var(--primary-8);
}

.hero .hero_img_wrapper img {
  @media only screen and (max-width: 768px) {
    height: 250px;
  }
  @media only screen and (max-width: 350px) {
    height: 200px;
  }
}
