.clients {
  /* background-color: var(--primary-1); */
  padding: 50px 0;
}
.clients h2 {
  text-align: center;
  margin-bottom: 1rem;
}
.clients p {
  text-align: center;
  margin-bottom: 2rem;
}
.client_slide {
  padding: 1rem;
  margin: 0px 20px;
}
.client_logo {
  filter: saturate(0.2);
  transition: filter 300ms, transform 300ms;
}
.client_logo:hover {
  filter: saturate(1);
  transform: scale(1.1, 1.1);
}
