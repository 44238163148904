.testi {
  padding: 50px 0;
}
.testi h2 {
  text-align: center;
}

.testi_slide {
  background-color: yellow;
  min-width: 100%;
  padding: 2rem;
}
