.inputField {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.inputField label {
  font-weight: 500;
  font-size: 1rem;
  text-transform: capitalize;
}
.inputField input,
.inputField textarea {
  padding: 0.75rem;
  border: none;
  outline: none;
  border: 1px solid var(--gray-5);
  font-size: 1rem;
  color: var(--gray-11);
  border-radius: 0.25rem;
  flex: 1;
}
.inputField .phone_field {
  width: 100%;
  display: flex;
  position: relative;
}
.inputField .phone_field .country_code {
  position: absolute;
  left: 0%;
  width: 100px;
  height: 100%;
  border-right: 1px solid var(--gray-5);
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
}
.inputField .phone_field .country_code span {
  font-size: 1rem;
  color: var(--gray-11);
  height: 100%;
  line-height: 50px;
}
.inputField .phone_field input {
  padding-left: 120px;
}
.inputField textarea {
  resize: none;
}
input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: 'tajawal';
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'tajawal';
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'tajawal';
}
