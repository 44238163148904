.navbar {
  background-color: var(--primary-a1);
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  z-index: 99;
}
.navbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* items wrapper  */
.navbar_items {
  display: flex;
  align-items: center;
  gap: 15rem;
  transition: left 500ms;
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: -100%;
    background-color: var(--primary-3);
    backdrop-filter: blur(4px);
    gap: 2rem;
  }
}
.navbar_items.show {
  @media only screen and (max-width: 991px) {
    left: 0;
  }
}
/* main links  */
.navbar_items .main_links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.navbar_items .main_links li,
.navbar_items .main_links button {
  font-size: 1rem;
  display: flex;
  border-radius: 0.25rem;
}
.navbar_items .main_links button {
  border: none;
  outline: none;
  padding: 10px;
  background: none;
  text-transform: capitalize;
  font-size: 1rem;
}
.navbar_items .main_links li:hover,
.navbar_items .main_links button:hover {
  background-color: var(--primary);
  color: var(--gray-1);
}
.navbar_items .main_links li button {
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
/* main actions  */
.navbar_items .main_actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}
.navbar_items .main_actions .action {
  outline: none;
  border: none;
  background: none;
  padding: 1rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: inherit;
}
.navbar_items .main_actions .action:nth-child(1) {
  background-color: var(--primary);
  color: var(--gray-1);
  gap: 0;
}

/* menu icon  */
.navbar_menu_icon {
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
    position: relative;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 50px;
  }
}
.navbar_menu_icon span {
  height: 2px;
  width: 40px;
  background-color: var(--primary-11);
  display: block;
  transition: transform 300ms;
}

.navbar_menu_icon.x_icon span:nth-of-type(2) {
  display: none;
}
.navbar_menu_icon.x_icon span:nth-of-type(1) {
  transform: translateY(3px) rotate(45deg);
}
.navbar_menu_icon.x_icon span:nth-of-type(3) {
  transform: translateY(-3px) rotate(-45deg);
}
