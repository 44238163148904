.about {
  padding: 50px 0;
  background: linear-gradient(
    to top,
    var(--gray-a1) 10%,
    var(--primary-1) 20%,
    var(--primary-4) 60%
  );
}
.about .about_container {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.about_container div:nth-of-type(1) {
  min-width: 100%;
  flex: 1;
  @media only screen and (min-width: 600px) {
    min-width: 450px;
  }
}
.about_container div:nth-of-type(1) h2 {
  font-size: 2rem;
  color: var(--primary-11);
}
.about_container div:nth-of-type(1) p {
  font-size: 1.3rem;
  color: var(--primary-9);
  line-height: 2rem;
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;
}
.about_container div:nth-of-type(1) span {
  display: inline;
}

.about_container .img_wrapper {
  margin: auto;
}
.about_container .img_wrapper[dir='ltr'] {
  transform: scaleX(-1);
}
