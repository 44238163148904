.dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  top: 0;
  /* background-color:var(--gray-a11); */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog .overlay {
  width: 100%;
  height: 100%;
  background-color: var(--gray-a11);
  position: absolute;
  z-index: -1;
}
.dialog_content {
  background-color: var(--gray-1);
  border-radius: 0.5rem;
  padding: 1rem;
  width: fit-content;
  overflow: auto;
  max-height: 100vh;
  max-height: 100svh;
}
