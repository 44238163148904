.slide {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  @media only screen and (max-width: 500px) {
    padding: 1.5rem;
  }
}
.slide p {
  text-align: center;
}

.slide .comment {
  color: var(--gray-10);
  line-height: 2rem;
  font-size: 1.2rem;
  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
}
.slide img {
  border: 2px solid var(--primary);
  border-radius: 50%;
  padding: 0.25rem;
  object-fit: cover;
}
.slide .name {
  font-weight: 600;
  color: var(--gray-11);
  font-size: 1.1rem;
}
.slide .title {
  font-weight: 400;
  color: var(--gray-9);
  font-size: 1.1rem;
}
