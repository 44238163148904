.apps {
  /* background-color: var(--primary-1); */
  padding: 50px 0;
}
.apps h2 {
  text-align: center;
  margin-bottom: 1rem;
}
.apps p {
  text-align: center;
  margin-bottom: 2rem;
}
.app_slide {
  padding: 1rem;
  margin: 0 20px;
}

.app_logo {
  filter: saturate(0.2);
  transition: filter 300ms, transform 300ms;
}
.app_logo:hover {
  filter: saturate(1);
  transform: scale(1.1, 1.1);
}
